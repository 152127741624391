/* ========================================================================== *\
   TABLE OF CONTENTS
\* ==========================================================================

	1. RESET
	2. TOKENS
    2.1 COLOURS
    2.2 ELEVATION
	3. GENERAL TAGS
	4. STRUCTURE
    4.1 LANDING PAGES
    4.2 BRAND
    4.3 SPLIT PAGE
  5. NAVIGATION
    5.1 MENU BUTTON
    5.2 TABS
  6. GENERAL CLASSES
	7. COLUMNS
*/

/* ========================================================================== *\
   1. RESET
\* ========================================================================== */

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, font, img, ins, kbd, q, s, samp,
small, strike, sub, sup, tt, var,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	font-weight: inherit;
	font-style: inherit;
	font-size: 100%;
	font-family: inherit;
	vertical-align: baseline;
}
/* remember to define focus styles! */
:focus {
	outline: 0;
}
body {
	line-height: 1;
	color: black;
	background: white;
}
ol, ul {
	list-style: none;
}
/* tables still need 'cellspacing="0"' in the markup */
table {
	border-collapse: separate;
	border-spacing: 0;
}
caption, th, td {
	text-align: left;
	font-weight: normal;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: "";
}
blockquote, q {
	quotes: "" "";
}

/* Make HTML 5 elements display block-level for consistent styling */
main, article, section, aside, hgroup, nav, header, footer, figure, figcaption {
  display: block;
}

::-moz-selection {
  color: var(--am-base-colour-midnight-700);
  background: var(--am-base-colour-electric-lime-500);
}

::selection {
  color: var(--am-base-colour-midnight-700);
  background: var(--am-base-colour-electric-lime-500);
}

/* ========================================================================== *\
   2. TOKENS
\* ========================================================================== */

/* ======================================= *\
   2.1. COLOURS
\* ======================================= */

:root {

  /* FUNCTIONAL COLORS */

  /* White */
  --am-base-colour-white: #ffffff;

  /* Steel - Used primarly for typographic elements, background fills, rules and strokes. */
  --am-base-colour-steel-900: #1C2D3F;
  --am-base-colour-steel-800: #2C3E50;
  --am-base-colour-steel-700: #384A5B;
  --am-base-colour-steel-600: #485B6C;
  --am-base-colour-steel-500: #5A6B7B;
  --am-base-colour-steel-400: #8A99A7;
  --am-base-colour-steel-300: #A6B1BB;
  --am-base-colour-steel-200: #C7D2DC;
  --am-base-colour-steel-100: #EDF1F4;

  /* Sapphire - Used for Buttons, links, backgrounds and icons. */
  --am-base-colour-sapphire-900: #1F68DA;
  --am-base-colour-sapphire-800: #2A75DB;
  --am-base-colour-sapphire-700: #4185E2;
  --am-base-colour-sapphire-600: #5B97EA;
  --am-base-colour-sapphire-500: #6FA6F1;
  --am-base-colour-sapphire-400: #9CC5FC;
  --am-base-colour-sapphire-300: #C3DDFF;
  --am-base-colour-sapphire-200: #DAEAFF;
  --am-base-colour-sapphire-100: #E5F1FF;

  /* Emerald - Used for success messages, focus states and highlights. */
  --am-base-colour-emerald-900: #16A78A;
  --am-base-colour-emerald-800: #24C9A9;
  --am-base-colour-emerald-700: #3BD6B8;
  --am-base-colour-emerald-600: #4FE1C5;
  --am-base-colour-emerald-500: #68E9D0;
  --am-base-colour-emerald-400: #98F1E0;
  --am-base-colour-emerald-300: #B8FAED;
  --am-base-colour-emerald-200: #D9FFF7;
  --am-base-colour-emerald-100: #EAFEFA;

  /* Ruby - Used for success messages, focus states and highlights. */
  --am-base-colour-ruby-900: #E71823;
  --am-base-colour-ruby-800: #FD4D55;
  --am-base-colour-ruby-700: #FE6067;
  --am-base-colour-ruby-600: #FC767C;
  --am-base-colour-ruby-500: #FF8388;
  --am-base-colour-ruby-400: #FF9498;
  --am-base-colour-ruby-300: #FFA6A9;
  --am-base-colour-ruby-200: #FFC5C7;
  --am-base-colour-ruby-100: #FFE0E1;

  /* Citrine - Used for success messages, focus states and highlights. */
  --am-base-colour-citrine-900: #E19708;
  --am-base-colour-citrine-800: #FFBD00;
  --am-base-colour-citrine-700: #FDC82F;
  --am-base-colour-citrine-600: #FFD14D;
  --am-base-colour-citrine-500: #FFD969;
  --am-base-colour-citrine-400: #FFE395;
  --am-base-colour-citrine-300: #FFEFC1;
  --am-base-colour-citrine-200: #FFF5D7;
  --am-base-colour-citrine-100: #FFFAEC;

  /* BRAND SOLID COLORS */

  /* Cash & Dream Miles */
  --am-base-colour-cash: #023438;
  --am-base-colour-dream: #592340;

  /* Midnight */
  --am-base-colour-midnight-900: #080E1A;
  --am-base-colour-midnight-800: #0F1D35;
  --am-base-colour-midnight-700: #19284C;
  --am-base-colour-midnight-600: #162C4F;
  --am-base-colour-midnight-500: #1D3B6A;
  --am-base-colour-midnight-400: #304E96;
  --am-base-colour-midnight-300: #5B82E0;
  --am-base-colour-midnight-200: #94AFF0;
  --am-base-colour-midnight-100: #D8E4FF;

  /* Lavender */
  --am-base-colour-lavender-900: #940FBD;
  --am-base-colour-lavender-800: #A53AC9;
  --am-base-colour-lavender-700: #B657D5;
  --am-base-colour-lavender-600: #C672E1;
  --am-base-colour-lavender-500: #D68BEC;
  --am-base-colour-lavender-400: #E5A7F8;
  --am-base-colour-lavender-300: #ECBDFA;
  --am-base-colour-lavender-200: #F3D5FC;
  --am-base-colour-lavender-100: #F9ECFD;

  /* Hot Pink */
  --am-base-colour-hot-pink-900: #DD0369;
  --am-base-colour-hot-pink-800: #E52177;
  --am-base-colour-hot-pink-700: #ED3384;
  --am-base-colour-hot-pink-600: #F54292;
  --am-base-colour-hot-pink-500: #FF499E;
  --am-base-colour-hot-pink-400: #FF7EB7;
  --am-base-colour-hot-pink-300: #FFA4CD;
  --am-base-colour-hot-pink-200: #FFC8E1;
  --am-base-colour-hot-pink-100: #FFEBF4;

  /* Electric Lime */
  --am-base-colour-electric-lime-900: #688D02;
  --am-base-colour-electric-lime-800: #73980F;
  --am-base-colour-electric-lime-700: #8EB81C;
  --am-base-colour-electric-lime-600: #AADA29;
  --am-base-colour-electric-lime-500: #C6FE01;
  --am-base-colour-electric-lime-400: #D2FD5E;
  --am-base-colour-electric-lime-300: #DEFD86;
  --am-base-colour-electric-lime-200: #E9FEAF;
  --am-base-colour-electric-lime-100: #F4FED7;

  /* Oatmylk */
  --am-base-colour-oatmylk-900: #FFE70A;
  --am-base-colour-oatmylk-800: #FFEA41;
  --am-base-colour-oatmylk-700: #FFED5F;
  --am-base-colour-oatmylk-600: #FFF079;
  --am-base-colour-oatmylk-500: #FFF391;
  --am-base-colour-oatmylk-400: #FFF6A8;
  --am-base-colour-oatmylk-300: #FFF8BF;
  --am-base-colour-oatmylk-200: #FFFBD5;
  --am-base-colour-oatmylk-100: #FFFDE9;

  /* GRADIENTS */

  /* Functional Gradients */
  --am-base-colour-steel-gradient: linear-gradient(90deg, #1C2D3F 0%, #384A5B 100%);
  --am-base-colour-sapphire-gradient: linear-gradient(90deg, #1F68DA 0%, #4185E2 100%);
  --am-base-colour-emerald-gradient: linear-gradient(90deg, #16A78A 0%, #3BD6B8 100%);
  --am-base-colour-ruby-gradient: linear-gradient(90deg, #E71823 0%, #FD4D55 100%);
  --am-base-colour-citrine-gradient: linear-gradient(90deg, #E19708 0%, #FDC82F 100%);
  --am-base-colour-skeleton-loader-gradient: linear-gradient(90deg, #C7D2DC 0%, #EDF1F4 50%, #C7D2DC 100%);

  /* Brand Gradients - Used for decorative container backgrounds, and for visual interest */
  --am-brand-colour-blue-purple-gradient: linear-gradient(90deg, #2A75DB 0%, #BDB4FF 100%);
  --am-brand-colour-purple-purple-gradient: linear-gradient(90deg, #D3B5FC 0%, #BEB5FF 100%);
  --am-brand-colour-pink-purple-gradient: linear-gradient(90deg, #EBB6F8 0%, #D6B5FB 100%);
  --am-brand-colour-tan-pink-gradient: linear-gradient(90deg, #ECD7C3 0%, #EEB6F7 100%);
  --am-brand-colour-citrine-gradient: linear-gradient(90deg, #E19708 0%, #FDC82F 100%);
  --am-brand-colour-yellow-tan-gradient: linear-gradient(90deg, #EBFC8A 0%,#ECDABF 100%);
  --am-brand-colour-yellow-pink-gradient: linear-gradient(272.9deg, #F6E8A2 0%, #EB886F 98.25%);
  --am-brand-colour-blue-blue-gradient: linear-gradient(272.9deg, #C3DDFF 0%, #4185E2 98.25%);
  --am-brand-colour-blue-green-gradient: linear-gradient(272.9deg, #A0D7E3 0%, #A9CC8F 98.25%);

  /* TIER COLORS */

  /* Blue */
  --am-base-colour-blue-900: #1F68DA;
  --am-base-colour-blue-800: #2A75DB;
  --am-base-colour-blue-700: #4185E2;
  --am-base-colour-blue-gradient: linear-gradient(90deg, #1F68DA 0%, #2A75DB 100%);

  /* Gold */
  --am-base-colour-gold-900: #785C09;
  --am-base-colour-gold-800: #8A6A0A;
  --am-base-colour-gold-700: #A98310;
  --am-base-colour-gold-gradient: linear-gradient(90deg, #785C09  0%, #8A6A0A 100%);

  /* Onyx */
  --am-base-colour-onyx-900: #000000;
  --am-base-colour-onyx-800: #111111;
  --am-base-colour-onyx-700: #222222;
  --am-base-colour-onyx-gradient: linear-gradient(90deg, #000000  0%, #222222 100%);
}

/* ======================================= *\
   2.2. ELEVATION
\* ======================================= */

:root {
  --am-base-elevation-1: 0px 4px 4px 0px rgba(40, 47, 64, 0.1);
  --am-base-elevation-2: 0px 8px 8px 0px rgba(40, 47, 64, 0.1);
  --am-base-elevation-3: 0px 12px 12px 0px rgba(40, 47, 64, 0.1);
}

/* ========================================================================== *\
   3. GENERAL TAGS
\* ========================================================================== */

img{max-width: 100%; height: auto !important;}
img{ -ms-interpolation-mode: bicubic; }

* {
	box-sizing: border-box;
}

html {
	font-size: 62.5%; /* == 10px rem == */
}

html, body {
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
}

body {
  display: block;
  background: var(--am-base-colour-white);
  font-size: 1.6rem;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
  line-height: 150%;
  color: var(--am-base-colour-midnight-700);
}

h1,h2,h3,h4,h5,h6,.dark-text {
	color: var(--am-base-colour-midnight-700);
  display: block;
}

p {
	padding-bottom: 2.4rem;
}

strong {
  color: var(--am-base-colour-midnight-700);
}

a,
.link {
  color: var(--am-base-colour-sapphire-900);
  text-decoration: underline;
  transition: all .3s ease-in-out;
	-webkit-transition: all .3s ease-in-out;
	-ms-transition: all .3s ease-in-out;
}

a:after,
a:before {
  transition: all .3s ease-in-out;
	-webkit-transition: all .3s ease-in-out;
	-ms-transition: all .3s ease-in-out;  
}

a:hover,
.link.hover {
  color: var(--am-base-colour-sapphire-900);
  text-decoration-thickness: 2px;
}

.link.focus {
  outline: 2px solid var(--am-base-colour-emerald-900);
}

.dark .link {
  color: var(--am-base-colour-electric-lime-300);
}

.dark.extra .link {
  color: var(--am-base-colour-electric-lime-500);
}

a[rel=noreferrer],
a[rel=external] {
  display: inline-block;
  padding-right: 2rem;
  min-height: 1.8rem;
  background: url(../public/images/icons/external_link.svg);
  background-size: 1.8rem 1.8rem;
  background-repeat: no-repeat;
  background-position: right center;
}

ol {
  list-style: none;
  counter-reset: item;
}

code {
  display: block;
  color: var(--am-base-colour-white);
  font-family: 'Courier New', Courier, monospace;
  font-weight: 600;
  border-radius: 0.8rem;
  background-color: var(--am-base-colour-midnight-800);
  padding: 3.2rem 2.4rem 0.8rem 3.2rem;
  margin-bottom: 2.4rem;
}

code ol {
  margin: 0 0 1.6rem 1.6rem;
}

code ol li {
  margin-bottom: 0.2rem;
  padding: 0 0 0 2.4rem;
}

code ol li:before {
  display: none;
}

code .indent {
  display: block
}

code .indent.level-1 {
  margin-left: 2.4rem;
}

code .indent.level-2 {
  margin-left: 4.8rem;
}

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

ol.inline {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  flex-flow: row wrap;
  align-items: center;
  gap: 2.4rem;
  margin-bottom: 3.2rem;
}

ol.inline li {
  align-items: center;
  margin-bottom: 0;
  min-height: 40px;
  display: flex;
  flex-direction: row;
}

ol li {
  counter-increment: item;
  margin-bottom: 1.6rem;
  padding-left: 4.8rem;
  padding-top: 1rem;
  position: relative;
}

ol li:before {
  position: absolute;
  left: 0;
  top: 0.4rem;
  margin-right: 0.8rem;
  font-weight: bold;
  font-size: 1.6rem;
  width: 3.2rem;
  height: 3.2rem;
  content: counter(item);
  background: var(--am-base-colour-white);
  border-radius: 100%;
  color: var(--am-base-colour-steel-900);
  border: 2px solid var(--am-base-colour-sapphire-900);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Open Sans", sans-serif;
}

hr {
  display: block;
  width: 100%;
  margin-bottom: 2.4rem;
  border-bottom: 3px solid var(--am-base-colour-steel-200);
  border-top: none;
  border-right: none;
  border-left: none;
  background: transparent;
}

.paragraph-special-type-eyebrow {
  display: block;
  font-size: 1.2rem;
  font-family: "Work Sans", sans-serif;
  font-weight: 700;
  font-style: normal;
  line-height: 150%;
  letter-spacing: 0.5em;
  text-transform: uppercase;
  padding-bottom: 0.8rem;
  color: var(--am-base-colour-sapphire-900);
}

.paragraph-special-type-eyebrow-slim {
  display: block;
  font-size: 1.2rem;
  font-family: "Work Sans", sans-serif;
  font-weight: 700;
  font-style: normal;
  line-height: 150%;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  padding-bottom: 0.8rem;
}

.newTitle {
  display: block;
  font-size: 1.2rem;
  font-family: "Work Sans", sans-serif;
  font-weight: 700;
  font-style: normal;
  line-height: 150%;
  letter-spacing: 0.5em;
  text-transform: uppercase;
  padding: 0.4rem 0 1.2rem 3.2rem;
  color: var(--am-base-colour-sapphire-900);
  background: url('../public/images/ico-new-releases.svg');
  background-size: 2.4rem 2.4rem;
  background-position: top left;
  background-repeat: no-repeat;
}

.xsmall {
  font-size: 1.2rem;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
  line-height: 150%;
}

.small {
  font-size: 1.4rem;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
  line-height: 150%;
}

.default {
  font-size: 1.6rem;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
  line-height: 150%;
}

.large {
  font-size: 2rem;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
  line-height: 150%;
}

.label {
  font-size: 1.6rem;
  font-family: "Work Sans", sans-serif;
  font-weight: 700;
  font-style: normal;
  line-height: 150%;
  text-decoration: none;
  text-transform: none;
  color: var(--am-base-colour-steel-900);
}

.title-level-1-large {
  font-size: 5.2rem;
  font-family: "Work Sans", sans-serif;
  font-weight: 800;
  font-style: normal;
  line-height: 115%;
  text-decoration: none;
  text-transform: none;
}

.title-level-1 {
  font-size: 4.2rem;
  font-family: "Work Sans", sans-serif;
  font-weight: 800;
  font-style: normal;
  line-height: 125%;
  text-decoration: none;
  text-transform: none;
}

.title-level-1-small {
  font-size: 3.8rem;
  font-family: "Work Sans", sans-serif;
  font-weight: 700;
  font-style: normal;
  line-height: 125%;
  text-decoration: none;
  text-transform: none;
}

.title-level-2-x-large {
  font-size: 5.2rem;
  font-family: "Work Sans", sans-serif;
  font-weight: 800;
  font-style: normal;
  line-height: 125%;
  text-decoration: none;
  text-transform: none;
}

.title-level-2-large {
  font-size: 3.8rem;
  font-family: "Work Sans", sans-serif;
  font-weight: 700;
  font-style: normal;
  line-height: 125%;
  text-decoration: none;
  text-transform: none;
}
.title-level-2 {
  font-size: 3rem;
  font-family: "Work Sans", sans-serif;
  font-weight: 700;
  font-style: normal;
  line-height: 125%;
  text-decoration: none;
  text-transform: none;
}

.title-level-2-small {
  font-size: 2.4rem;
  font-family: "Work Sans", sans-serif;
  font-weight: 700;
  font-style: normal;
  line-height: 150%;
  text-decoration: none;
  text-transform: none;
}

.title-level-2-x-small {
  font-size: 2rem;
  font-family: "Work Sans", sans-serif;
  font-weight: 700;
  font-style: normal;
  line-height: 150%;
  text-decoration: none;
  text-transform: none;
}

.title-level-3-large {
  font-size: 2rem;
  font-family: "Work Sans", sans-serif;
  font-weight: 700;
  font-style: normal;
  line-height: 150%;
  text-decoration: none;
  text-transform: none;
}

.title-level-3 {
  font-size: 1.8rem;
  font-family: "Work Sans", sans-serif;
  font-weight: 700;
  font-style: normal;
  line-height: 150%;
  text-decoration: none;
  text-transform: none;
}

.title-level-3-small {
  font-size: 1.6rem;
  font-family: "Work Sans", sans-serif;
  font-weight: 700;
  font-style: normal;
  line-height: 150%;
  text-decoration: none;
  text-transform: none;
}

.title-level-4 {
  font-size: 1.6rem;
  font-family: "Work Sans", sans-serif;
  font-weight: 700;
  font-style: normal;
  line-height: 150%;
  text-decoration: none;
  text-transform: none;
}

.title-level-5 {
  font-size: 1.4rem;
  font-family: "Work Sans", sans-serif;
  font-weight: 700;
  font-style: normal;
  line-height: 150%;
  text-decoration: none;
  text-transform: none;
}

.title-level-6 {
  font-size: 1.2rem;
  font-family: "Work Sans", sans-serif;
  font-weight: 700;
  font-style: normal;
  line-height: 150%;
  text-decoration: none;
  text-transform: none;
}

/* ==== TABLET ==== */

.title-level-1-large.tablet {
  font-size: 4.8rem;
  line-height: 125%;
}

.title-level-1.tablet {
  font-size: 4.2rem;
  line-height: 125%;
}

.title-level-1-small.tablet {
  font-size: 3.6rem;
  line-height: 125%;
}

.title-level-2-x-large.tablet {
  font-size: 4.2rem;
  line-height: 125%;
}

.title-level-2-large.tablet {
  font-size: 3.6rem;
  line-height: 125%;
}
.title-level-2.tablet {
  font-size: 3rem;
  line-height: 125%;
}

.title-level-2-small.tablet {
  font-size: 2.4rem;
  line-height: 125%;
}

.title-level-2-x-small.tablet {
  font-size: 2rem;
  line-height: 125%;
}

.title-level-3-large.tablet {
  font-size: 2rem;
  line-height: 125%;
}

.title-level-3.tablet {
  font-size: 1.8rem;
  line-height: 150%;
}

.title-level-3-small.tablet {
  font-size: 1.6rem;
  line-height: 125%;
}

.title-level-4.tablet {
  font-size: 1.6rem;
  line-height: 125%;
}

.title-level-5.tablet {
  font-size: 1.4rem;
  line-height: 125%;
}

.title-level-6.tablet {
  font-size: 1.2rem;
  line-height: 125%;
}

/* ==== MOBILE ==== */

.title-level-1-large.mobile {
  font-size: 4rem;
  line-height: 125%;
}

.title-level-1.mobile {
  font-size: 3.6rem;
  line-height: 125%;
}

.title-level-1-small.mobile {
  font-size: 3.0rem;
  line-height: 125%;
}

.title-level-2-x-large.mobile {
  font-size: 3.6rem;
  line-height: 125%;
}

.title-level-2-large.mobile {
  font-size: 3rem;
  line-height: 125%;
}
.title-level-2.mobile {
  font-size: 2.4rem;
  line-height: 125%;
}

.title-level-2-small.mobile {
  font-size: 2rem;
  line-height: 125%;
}

.title-level-2-x-small.mobile {
  font-size: 1.8rem;
  line-height: 125%;
}

.title-level-3-large.mobile {
  font-size: 2rem;
  line-height: 125%;
}

.title-level-3.mobile {
  font-size: 1.8rem;
  line-height: 150%;
}

.title-level-3-small.mobile {
  font-size: 1.6rem;
  line-height: 125%;
}

.title-level-4.mobile {
  font-size: 1.6rem;
  line-height: 125%;
}

.title-level-5.mobile {
  font-size: 1.4rem;
  line-height: 125%;
}

.title-level-6.mobile {
  font-size: 1.2rem;
  line-height: 125%;
}

/* ==== APP TYPOGRAPHY ==== */

.app-headline-large {
	font-size: 32px;
	font-family: "Work Sans";
	font-weight: 700;
	font-style: normal;
	line-height: 40px;
	letter-spacing: -0.4px;
	text-decoration: none;
	text-transform: none;
}

.app-headline-medium {
	font-size: 28px;
	font-family: "Work Sans";
	font-weight: 700;
	font-style: normal;
	line-height: 36px;
	letter-spacing: -0.4px;
	text-decoration: none;
	text-transform: none;
}

.app-headline-small {
	font-size: 24px;
	font-family: "Work Sans";
	font-weight: 700;
	font-style: normal;
	line-height: 32px;
	letter-spacing: -0.2px;
	text-decoration: none;
	text-transform: none;
}

.app-title-large {
	font-size: 22px;
	font-family: "Work Sans";
	font-weight: 700;
	font-style: normal;
	line-height: 28px;
	letter-spacing: -0.2px;
	text-decoration: none;
	text-transform: none;
}

.app-title-medium {
	font-size: 18px;
	font-family: "Work Sans";
	font-weight: 700;
	font-style: normal;
	line-height: 24px;
	letter-spacing: -0.2px;
	text-decoration: none;
	text-transform: none;
}

.app-title-small {
	font-size: 16px;
	font-family: "Work Sans";
	font-weight: 700;
	font-style: normal;
	line-height: 24px;
	letter-spacing: -0.2px;
	text-decoration: none;
	text-transform: none;
}

.app-body-x-small {
	font-size: 10px;
	  font-family: "Open Sans", sans-serif;
	font-weight: 400;
	font-style: normal;
	line-height: 14px;
	text-decoration: none;
	text-transform: none;
}

.app-body-small {
	font-size: 12px;
	font-family: "Open Sans", sans-serif;
	font-weight: 400;
	font-style: normal;
	line-height: 16px;
	text-decoration: none;
	text-transform: none;
}

.app-body-medium {
	font-size: 14px;
	font-family: "Open Sans", sans-serif;
	font-weight: 400;
	font-style: normal;
	line-height: 20px;
	text-decoration: none;
	text-transform: none;
}

.app-body-large {
	font-size: 16px;
	font-family: "Open Sans", sans-serif;
	font-weight: 400;
	font-style: normal;
	line-height: 24px;
	text-decoration: none;
	text-transform: none;
}

.app-body-x-large {
	font-size: 18px;
	font-family: "Work Sans", sans-serif;
	font-weight: 400;
	font-style: normal;
	line-height: 24px;
	text-decoration: none;
	text-transform: none;
}

.app-other-caption {
	font-size: 12px;
	font-family: "Open Sans", sans-serif;
	font-weight: 400;
	font-style: normal;
	line-height: 16px;
	letter-spacing: 1px;
	text-decoration: none;
	text-transform: uppercase;
}

.app-other-fine-print {
	font-size: 13px;
	  font-family: "Open Sans", sans-serif;
	font-weight: 400;
	font-style: normal;
	line-height: 16px;
	text-decoration: none;
	text-transform: none;
}

.app-label-large {
	font-size: 14px;
	font-family: "Work Sans";
	font-weight: 600;
	font-style: normal;
	line-height: 20px;
	letter-spacing: 1px;
	text-decoration: none;
	text-transform: uppercase;
}

.app-label-medium {
	font-size: 12px;
	font-family: "Work Sans";
	font-weight: 600;
	font-style: normal;
	line-height: 16px;
	letter-spacing: 1px;
	text-decoration: none;
	text-transform: uppercase;
}

.app-label-small {
  font-size: 10px;
    font-family: "Work Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
  line-height: 12px;
  text-decoration: none;
  text-transform: none;
}

/* === */

.light-weight {
  font-weight: 500;
}

.anchor {
  padding-top: 2.4rem;
}

.iframe {
  display: block;
  width: 100%;
  min-height: 400px;
  border-radius: 16px;
  border: 1px solid var(--am-base-colour-steel-200);
  background: var(--am-base-colour-white);
}

/* ========================================================================== *\
   4. STRUCTURE
\* ========================================================================== */

.header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
  height: 95px;
	padding: 2.6rem 2.4rem 1.6rem 2.4rem;
	background: var(--am-base-colour-white);
  border-bottom: 1px solid var(--am-base-colour-steel-200);
  position: relative;
}

.header::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1rem;
  background: var(--am-base-colour-sapphire-900);
}

.header .columns {
  gap: 1.6rem;
  align-items: center;
}

.header .platform {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.8rem;
  padding: 0.8rem 1.6rem;
  font-weight: bold;
  font-size: 1.2rem;
  letter-spacing: 2px;
  border-radius: 3.2rem;
  text-transform: uppercase;
}

.header .platform.web {
  background: var(--am-base-colour-electric-lime-500);
  color: var(--am-base-colour-midnight-700);
}

.header .platform.web:before {
  content: "";
  display: block;
  width: 2.4rem;
  height: 2.4rem;
  background: url('/public/images/icons/other/browse_rewards.svg');
  background-size: 2.4rem 2.4rem;
  background-position: center center;
  background-repeat: no-repeat;
}

.header .platform.app {
  background: var(--am-base-colour-hot-pink-900);
  color: var(--am-base-colour-white);
}

.header .platform.app:before {
  content: "";
  display: block;
  width: 2.4rem;
  height: 2.4rem;
  background: url('/public/images/icons/other/app_ds.svg');
  background-size: 2.4rem 2.4rem;
  background-position: center center;
  background-repeat: no-repeat;
}

.back-to-top {
  position: fixed;
  bottom: 2.4rem;
  right: 2.4rem;
  width: 4.8rem;
  height: 4.8rem;
  z-index: 1000;
  background: url('../public/images/arrow_up-white.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 2.4rem 2.4rem;
  text-indent: -999px;
  overflow: hidden;
  border-radius: 100%;
  background-color: var(--am-base-colour-sapphire-900);
  box-shadow: var(--am-base-elevation-2);
  color: var(--am-base-colour-white);
  border: none;
  cursor: pointer;
  opacity: 0;
  transition: all .3s ease-in-out;
	-webkit-transition: all .3s ease-in-out;
	-ms-transition: all .3s ease-in-out;
}

.back-to-top:hover {
  background: url('../public/images/arrow_up-starry-sky-900.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 2.4rem 2.4rem;
  background-color: var(--am-base-colour-electric-lime-600);
}

.back-to-top.show {
  opacity: 1;
}

.logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.8rem;
  color: var(--am-base-colour-sapphire-900);
  font-size: 1.2rem;
  line-height: 125%;
  font-family: "Work Sans", sans-serif;
}

.logo-full {
  display: block;
  width: 20rem;
}

.logo-mark {
  display: block;
  width: 5.6rem;
  height: 4.8rem;
  background: url('../public/images/logo.svg');
  background-size: 5.6rem 4.8rem;
  background-position: center center;
  background-repeat: no-repeat;
}

.logo .logo-name {
  display: block;
}

.logo strong {
  display: block;
  font-size: 2rem;
  line-height: 125%;
  font-weight: 700;
  color: var(--am-base-colour-midnight-700);
}

.container {
  display: flex;
  flex-direction: row;
  height: 100%;
}

body {
  overflow: hidden;
}

.content {
  flex: 1;
  height: calc(100vh - 95px);
  overflow-y: auto;
  border-left: solid 1px var(--am-base-colour-steel-200);
}

.content .inner {
  width: 100%;
  max-width: 1240px;
  padding: 4.8rem 4.8rem 8rem 4.8rem;
  margin: 0 auto;
}

.titleBar {
  display: block;
  width: 100%;
  margin-bottom: 2.4rem;
  border-bottom: 3px solid var(--am-base-colour-steel-200);
}

.content .inner .main {
  display: block;
  max-width: 76.7%;
  width: 100%;
}

.main ul,
.main ol,
ul.bullets {
  display: block;
  margin: 0 0 2.4rem 2.4rem;
}

.main ul li,
ul.bullets li {
  list-style-type: disc;
  margin-bottom: 0.4rem;
}

.main ul ul,
ul.bullets ul {
  margin: 0.4rem 0 0 1.6rem;
}

.main ul li ul li,
ul.bullets li ul li {
  list-style-type: circle;
}

.main ol li {
  /* list-style-type: decimal; */
  margin-bottom: 1.2rem;
}

.main ol li ol li {
  list-style-type: lower-alpha;
}

.content .inner .subNav {
  display: block;
  width: 20%;
  position: sticky;
  top: 2.4rem;
  left: 0;
  z-index: 100;
  height: 100%;
  color: var(--am-base-colour-steel-900);
}

.content .inner .subNav ul {
  display: block;
  margin: 0;
  border: 1px solid var(--am-base-colour-steel-200);
  border-radius: 0.8rem;
  padding: 1.6rem;
}

.content .inner .subNav ul li {
  display: block;
  margin: 0 0 0.4rem 0;
}

.content .inner .subNav ul li:first-child {
  font-weight: 700;
  margin-bottom: 0.8rem;
  padding-left: 0.8rem;
}

.content .inner .subNav ul li a {
  display: block;
  font-size: 1.4rem;
  line-height: 150%;
  padding: 0.4rem 0.8rem;
  border-radius: 0.4rem;
  color: var(--am-base-colour-midnight-700);
  background: var(--am-base-colour-white);
  text-decoration: none;
}

.content .inner .subNav ul li a:hover {
  background: var(--am-base-colour-sapphire-200);
}

.content .inner .subNav ul li a:focus {
  color: var(--am-base-colour-white);
  background: var(--am-base-colour-sapphire-900);
}

.change-log-container {
  display: flex;
  flex-direction: column;
  color: var(--am-base-colour-steel-900);
  margin-bottom: 4.8rem;
  width: 100%;
}

.change-log-container .change-log-container-header {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 1.6rem;
  font-weight: 700;
  padding: 1.6rem 0;
  font-size: 1.6rem;
  line-height: 125%;
  border-bottom: 1px solid var(--am-base-colour-steel-200);
}

.change-log-container .change-log-container-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 1.6rem;
  font-weight: 400;
  padding: 1.6rem 0;
  font-size: 1.4rem;
  line-height: 125%;
  border-bottom: 1px solid var(--am-base-colour-steel-200);
}

.change-log-container .col {
  display: block;
  max-width: 150px;
  width: 100%;
}

.change-log-container .col:last-child {
  max-width: 100%;
}

.change-log-container .col ul {
  margin: 0 0 0 1.6rem;
}

.change-log-container .col ul li {
  display: list-item;
  list-style-type: disc;
  margin-bottom: 0.4rem;
}

.change-log-container .col ul li ul {
  margin-top: 0.4rem;
}

.change-log-container .col ul li ul li {
  list-style-type: circle;
}

.table {
  display: block;
}

.sticky {
  position: sticky;
  top: -0.1rem;
  z-index: 5;
}

.sticky.offset {
  top: 4.8rem;
}

.table table {
  width: 100%;
  font-size: 1.6rem;
  line-height: 125%;
  border-left: 1px solid var(--am-base-colour-steel-200);
  border-right: 1px solid var(--am-base-colour-steel-200);
  border-top: 1px solid var(--am-base-colour-steel-200);
  border-bottom: none;
}

.table table thead {
  width: 100%;
  border: none;
  background: var(--am-base-colour-white);
}

.table table thead tr {
  width: 100%;
}

.table table thead tr th {
  border-bottom: 1px solid var(--am-base-colour-steel-200);
  border-top: none;
  border-left: none;
  border-right: none;
  padding: 1.6rem 2.4rem;
  color: var(--am-base-colour-steel-900);
  font-weight: 700;
  background: var(--am-base-colour-sapphire-200);
}

.table table tbody {
  width: 100%;
  border: none;
}

.table table tbody tr {
  width: 100%;
}

.table table tbody tr td {
  border-left: none;
  border-right: none;
  border-top: none;
  padding: 1.6rem 2.4rem;
  border-bottom: 1px solid var(--am-base-colour-steel-200);
  color: var(--am-base-colour-steel-900);
  font-size: 1.4rem;
  max-width: 350px;
  min-width: 40px;
  position: relative;
}

.table table tbody tr td ul {
  margin: 1.6rem 0 1.6rem 3.2rem;
}

.table table tbody tr td ul li {
  list-style-type: disc;
}

.table table tbody tr th {
  position: relative;
}

.table table tbody tr td div,
.table table tbody tr th div {
  padding-right: 2.4rem;
}

.table table tbody tr td .badge,
.table table tbody tr th .badge {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.2rem;
  color: var(--am-base-colour-midnight-700);
  padding: 0.8rem;
  width: 6.4rem;
  height: 6.4rem;
  background:  url('../public/images/flower-4.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  top: 50%;
  right: -2.4rem;
  transform: translateY(-50%);
}

.table table tbody tr th {
  background: var(--am-base-colour-sapphire-100);
  border-left: none;
  border-right: none;
  border-bottom: 1px solid var(--am-base-colour-steel-200);
  border-top: none;
  padding: 1.6rem 2.4rem;
  font-weight: 700;
  color: var(--am-base-colour-steel-900);
}

.table table tbody tr th small {
  display: block;
  font-size: 1.4rem;
  font-weight: 400;
}

.table table tbody tr td .colour-thumb {
  display: block;
  width: 5.6rem;
  height: 5.6rem;
  border-radius: 0.8rem;
}

.table table tbody tr td .colour-thumb.border {
  border: 1px solid var(--am-base-colour-steel-200);
}

.table table tbody tr td .figma-btn {
  margin-bottom: 0;
}

ul.icon-grid {
  display: flex;
  flex-flow: row wrap;
  gap: 1.6rem;
  margin-bottom: 4.8rem;
}

ul.icon-grid li img {
  position: relative;
}

/* ======================================= *\
   4.1. LANDING PAGES
\* ======================================= */

.landingPageGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3.2rem;
  margin-top: 2.4rem;
}

.landingPageGrid .tile {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 0.8rem;
  padding: 2.4rem 2.4rem 0rem 2.4rem;
  background: var(--am-base-colour-white);
  border: 1px solid var(--am-base-colour-steel-200);
  box-shadow: 0px 2px 2px 0px #282F401A;
  position: relative;
}

.landingPageGrid .tile::before {
  content: "";
  display: block;
  width: 100%;
  height: 0.8rem;
  border-radius: 0.8rem 0.8rem 0 0;
  position: absolute;
  top: 0;
  left: 0;
  background: var(--am-base-colour-hot-pink-600);
}

.landingPageGrid .tile .title-level-3-large {
  color: var(--am-base-colour-steel-900);
}

/* ======================================= *\
   4.2. BRAND
\* ======================================= */

.brandColours {
  display: grid;
  grid-template-columns: repeat(2,1fr);
  gap: 2.4rem;
}

.brandColours.third {
  grid-template-columns: repeat(3,1fr);
}

.brandColours.fourth {
  grid-template-columns: repeat(4,1fr);
}

.brandColours.single {
  grid-template-columns: repeat(1,1fr);
}

.brandColours.third .tile .colour {
  height: 20rem;
}

.brandColours .tile {
  display:flex;
  flex-direction: column;
  gap: 2.4rem;
}

.brandColours .tile .title {
  font-size: 2.4rem;
  font-weight: bold;
}

.brandColours .tile small {
  text-transform: none;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 150%;
  display: block;
}

.brandColours .tile .colour {
  display: block;
  padding: 2.4rem;
  color:var(--am-base-colour-white);
  height: 40rem;
  border-radius: 0.8rem;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.4rem;
}

.brandColours .tile .colour.sapphire900 {
  background-color: var(--am-base-colour-sapphire-900);
}

.brandColours .tile .colour.sapphire100 {
  background-color: var(--am-base-colour-sapphire-100);
  color: var(--am-base-colour-midnight-700);
}

.brandColours .tile .colour.sapphire200 {
  background-color: var(--am-base-colour-sapphire-200);
  color: var(--am-base-colour-midnight-700);
}

.brandColours .tile .colour.lavender {
  background-color: var(--am-base-colour-lavender-400);
  color: var(--am-base-colour-midnight-700);
}

.brandColours .tile .colour.electricLimeText {
  color: var(--am-base-colour-electric-lime-600);
}

.brandColours .tile .colour.hotPinkText {
  color: var(--am-base-colour-hot-pink-500);
}

.brandColours .tile .colour.lavenderText {
  color: var(--am-base-colour-lavender-400);
}

.brandColours .tile .colour.sapphireText {
  color: var(--am-base-colour-sapphire-900) !important;
}

.brandColours .tile .colour.midnight {
  background-color: var(--am-base-colour-midnight-700);
}

.brandColours .tile .colour.white {
  color: var(--am-base-colour-midnight-700);
  background-color: var(--am-base-colour-white);
  border: 1px solid var(--am-base-colour-steel-200);
}

.brandColours .tile .colour.hotPink900 {
  background-color: var(--am-base-colour-hot-pink-900);
  color: var(--am-base-colour-white);
}

.brandColours .tile .colour.hotPink {
  background-color: var(--am-base-colour-hot-pink-500);
}

.brandColours .tile .colour.lavender {
  background-color: var(--am-base-colour-lavender-400);
}

.brandColours .tile .colour.electricLime {
  background-color: var(--am-base-colour-electric-lime-500);
  color: var(--am-base-colour-midnight-700);
}

.brandColours .tile .colour.oatmylk {
  background-color: var(--am-base-colour-oatmylk-100);
  color: var(--am-base-colour-midnight-700);
}

.brandColours .tile .colour.gold {
  background-color: var(--am-base-colour-gold-800);
}

.brandColours .tile .colour.onyx {
  background-color: var(--am-base-colour-onyx-700);
}

.brandColours .tile .colour.blue {
  background-color: var(--am-base-colour-blue-800);
}

.brandColours .tile p {
  display: block;
  font-size: 1.2rem;
  line-height: 150%;
}

.brandColours .tile strong {
  text-transform: uppercase;
  font-size: 1.4rem;
}

.electric-blue-text,
.midnight-blue-text,
.black-text,
.iceberg-text {
  font-weight: bold;
}

.electric-blue-text {
  color: var(--am-base-colour-sapphire-800);
}

.midnight-blue-text {
  color: var(--am-base-colour-midnight-700);
}

.black-text {
  color: #000000;
}

.iceberg-text {
  color: var(--am-base-colour-sapphire-200);
  background: var(--am-base-colour-midnight-700);
}


/* ======================================= *\
   4.3. SPLIT PAGE
\* ======================================= */

.choose-btn {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-decoration: none;
  color: var(--am-base-colour-white);
  position: relative;
  border-radius: 1.6rem;
  padding: 2.4rem 2.4rem 1.6rem 2.4rem;
  height: 40rem;
}

.choose-btn .graphic .device,
.choose-btn .graphic .flair-1,
.choose-btn .graphic .flair-2 {
  transition: all .3s ease-in-out;
	-webkit-transition: all .3s ease-in-out;
	-ms-transition: all .3s ease-in-out; 
}

.choose-btn:hover {
  color: var(--am-base-colour-white);
}

.choose-btn.web {
  background: radial-gradient(86.45% 542.67% at 102.75% 94.9%, #164483 0%, #19284C 100%);
}

.choose-btn.web .graphic {
  display: block;
  width: 24rem;
  position: absolute;
  right: 6.4rem;
  top: -2.4rem;
}

.choose-btn.web .graphic .flair-1 {
  display: block;
  width: 3.2rem;
  position: absolute;
  top: 6.4rem;
  left: 1.6rem;
}

.choose-btn.web .graphic .flair-2 {
  display: block;
  width: 3.2rem;
  position: absolute;
  top: 23rem;
  right: 1.6rem
}

.choose-btn.web .graphic .device {
  display: block;
  width: 28rem;
}

.choose-btn.app {
  background: radial-gradient(51.83% 96.09% at 100% 96.4%, #3985EB 0%, #1F68DA 100%);
}

.choose-btn.app .graphic {
  display: block;
  position: absolute;
  right: 7.2rem;
  top: -2.4rem;
  width: 15rem;
}

.choose-btn.app .graphic .flair-1 {
  display: block;
  width: 4rem;
  position: absolute;
  bottom: 4.8rem;
  left: -4rem; 
}

.choose-btn.app .graphic .flair-2 {
  display: block;
  width: 3.2rem;
  position: absolute;
  top: 5.6rem;
  right: -4rem 
}

.choose-btn.app .graphic .device {
  display: block;
  width: 15rem;
}

.choose-btn h2,
.choose-btn p {
  color: var(--am-base-colour-white);
}

.choose-btn .btn.tertiary,
.choose-btn .btn.tertiary:hover {
  color: var(--am-base-colour-white);
}

.choose-btn .btn.tertiary:after  {
  background: url('../public/images/ico-right-arrow-white.svg');
  background-size: 2.4rem 2.4rem;
  background-repeat: no-repeat;
  transition: all .3s ease-in-out;
	-webkit-transition: all .3s ease-in-out;
	-ms-transition: all .3s ease-in-out; 
}

.choose-btn:hover .btn.tertiary:after  {
  transform: translateX(0.4rem);
}

.choose-btn:hover .graphic .device {
  transform: scale(1.1,1.1);
}

.choose-btn:hover .graphic .flair-1 {
  transform: rotate(45deg);
}

.choose-btn:hover .graphic .flair-2 {
  transform: scale(1.1,1.1) rotate(-10deg);
}

/* ========================================================================== *\
   5. NAVIGATION
\* ========================================================================== */

.nav {
  max-width: 24rem;
  display: block;

  flex: 1;
  height: calc(100vh - 95px);
  overflow-y: auto;
  background: var(--am-base-colour-white);
}

.collapse-btn {
  display: block;
  padding: 1.6rem 1.6rem 1.6rem 4.8rem;
  cursor: pointer;
  font-size: 1.2rem;
  letter-spacing: 1px;
  color: var(--am-base-colour-sapphire-900);
  font-weight: 700;
  text-transform: uppercase;
  font-family: "Work Sans", sans-serif;
  background: url('../public/images/icons/chevron_double_left.svg');
  background-position: left 1.6rem center;
  background-repeat: no-repeat;
  background-size: 2.4rem 2.4rem;
  transition: all .3s ease-in-out;
	-webkit-transition: all .3s ease-in-out;
	-ms-transition: all .3s ease-in-out;
}

.collapse-btn:hover {
  background: url('../public/images/icons/chevron_double_left.svg');
  background-position: left 1.2rem center;
  background-repeat: no-repeat;
  background-size: 2.4rem 2.4rem;
  color: var(--am-base-colour-midnight-700);
}

.nav.landing-page {
  display: none;
}

.nav ul,
.nav ul li,
.nav ul li ul,
.nav ul li ul li {
  margin: 0;
  display: block;
}

.nav ul li,
.nav ul li a {
  display: block;
}

.nav ul {
  padding-bottom: 4.8rem;
}

.nav ul ul {
  padding-bottom: 0;
}

.nav ul li:first-child .accordion-label {
  border-top: none;
}

.nav ul li:last-child .accordion-label {
  border-bottom: 1px solid var(--am-base-colour-steel-200);
}

.nav .accordion-label {
  cursor: pointer;
  padding: 10px;
  background-color: var(--am-base-colour-white);
  border-top: 1px solid var(--am-base-colour-steel-200);
}

.nav .accordion-label:hover {
  background-color: var(--am-base-colour-sapphire-200);
  border-top: 1px solid transparent;
}

.nav .accordion-content {
  display: none;
  /* max-height: 240px; */
  overflow-y: auto;
}

.nav .accordion-content.is-active {
  display: block;
  /* position: relative; */
}

/*
.nav .accordion-content.is-active:before {
  content: "";
  display: block;
  width: 100%;
  height: 4.8rem;
  position: absolute;
  bottom: 0px;
  left: 0px;
  background: linear-gradient(0deg, rgba(255,255,255,1) 0, rgba(255,255,255,0) 90%);
}
*/

.nav .navbar-item {
  padding: 0;
  text-decoration: none;
  color: var(--am-base-colour-steel-900);

  background: url('../public/images/icons/plus.svg');
  background-position: right 1.6rem center;
  background-repeat: no-repeat;
  background-size: 2.4rem 2.4rem;
  background-color: var(--am-base-colour-white);

  transition: all .3s ease-in-out;
	-webkit-transition: all .3s ease-in-out;
	-ms-transition: all .3s ease-in-out;
}

.nav .navbar-item[rel=noreferrer] {
  display: inline-block;
  padding-right: 2rem;
  min-height: 1.8rem;
  background: url(../public/images/icons/external_link.svg);
  background-size: 1.8rem 1.8rem;
  background-repeat: no-repeat;
  background-position: right 1.2rem top 1.2rem;
}

.nav .navbar-item:hover {
  background-color: var(--am-base-colour-sapphire-200);
}

.nav .navbar-item a,
.nav .navbar-item span {
  display: block;
  padding: 1.2rem 4.8rem;
}

.nav .navbar-item.is-active {
  background: 
    url('../public/images/icons/minus-white.svg'),
    var(--am-base-colour-sapphire-900);
  background-position: 
    right 1.6rem center,
    left top;
  background-repeat: no-repeat;
  border-top: 1px solid transparent;
  color: var(--am-base-colour-white);
}

.nav li.back a {
  border-top: 1px solid var(--am-base-colour-steel-200);
  
  background: url('../public/images/ico-left-arrow.svg');
  background-position: left 1.6rem center;
  background-repeat: no-repeat;
  background-size: 2.4rem 2.4rem;
  background-color: var(--am-base-colour-white);

  padding: 1.6rem 2.4rem 1.6rem 4.8rem;
  color: var(--am-base-colour-sapphire-900);
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1.2rem;
  letter-spacing: 1px;
  text-decoration: none;
}

.nav li.back a:hover {
  background-position: left 0.8rem center;
}

/* CUSTOM ICONS */

.nav .overview,
.nav .foundation,
.nav .patterns,
.nav .tokens,
.nav .accessibility,
.nav .brand,
.nav .resources,
.nav .tutorials,
.nav .web,
.nav .app {
  color: var(--am-base-colour-steel-900);
}

.nav .navbar-item.is-active .overview,
.nav .navbar-item.is-active .foundation,
.nav .navbar-item.is-active .patterns,
.nav .navbar-item.is-active .tokens,
.nav .navbar-item.is-active .accessibility,
.nav .navbar-item.is-active .brand,
.nav .navbar-item.is-active .resources,
.nav .navbar-item.is-active .tutorials,
.nav .navbar-item.is-active .web,
.nav .navbar-item.is-active .app {
  color: var(--am-base-colour-white);
}

.nav .web {
  background: url('../public/images/icons/other/browse_rewards.svg');
  background-size: 2.4rem 2.4rem;
  background-repeat: no-repeat;
  background-position: left 1.6rem center;
}

.nav .navbar-item.is-active .web {
  background: url('../public/images/icons/other/browse_rewards.svg');
  background-size: 2.4rem 2.4rem;
  background-repeat: no-repeat;
  background-position: left 1.6rem center;
}

.nav .app {
  background: url('../public/images/icons/other/app_ds_midnight.svg');
  background-size: 2.4rem 2.4rem;
  background-repeat: no-repeat;
  background-position: left 1.6rem center;
}

.nav .navbar-item.is-active .app {
  background: url('../public/images/icons/other/app_ds_midnight.svg');
  background-size: 2.4rem 2.4rem;
  background-repeat: no-repeat;
  background-position: left 1.6rem center;
}

.nav .overview {
  background: url('../public/images/icons/home.svg');
  background-size: 2.4rem 2.4rem;
  background-repeat: no-repeat;
  background-position: left 1.6rem center;
}

.nav .navbar-item.is-active .overview {
  background: url('../public/images/icons/home-white.svg');
  background-size: 2.4rem 2.4rem;
  background-repeat: no-repeat;
  background-position: left 1.6rem center;
}

.nav .foundation {
  background: url('../public/images/icons/tier_benefits.svg');
  background-size: 2.4rem 2.4rem;
  background-repeat: no-repeat;
  background-position: left 1.6rem center;
}

.nav .foundation {
  background: url('../public/images/icons/tier_benefits.svg');
  background-size: 2.4rem 2.4rem;
  background-repeat: no-repeat;
  background-position: left 1.6rem center;
}

.nav .navbar-item.is-active .foundation {
  background: url('../public/images/icons/tier_benefits-white.svg');
  background-size: 2.4rem 2.4rem;
  background-repeat: no-repeat;
  background-position: left 1.6rem center;
}

.nav .patterns {
  background: url('../public/images/icons/patterns.svg');
  background-size: 2.4rem 2.4rem;
  background-repeat: no-repeat;
  background-position: left 1.6rem center;
}

.nav .navbar-item.is-active .patterns {
  background: url('../public/images/icons/patterns-white.svg');
  background-size: 2.4rem 2.4rem;
  background-repeat: no-repeat;
  background-position: left 1.6rem center;
}

.nav .tokens {
  background: url('../public/images/icons/tokens.svg');
  background-size: 2.4rem 2.4rem;
  background-repeat: no-repeat;
  background-position: left 1.6rem center;
}

.nav .navbar-item.is-active .tokens {
  background: url('../public/images/icons/tokens-white.svg');
  background-size: 2.4rem 2.4rem;
  background-repeat: no-repeat;
  background-position: left 1.6rem center;
}

.nav .accessibility {
  background: url('../public/images/icons/accessibility.svg');
  background-size: 2.4rem 2.4rem;
  background-repeat: no-repeat;
  background-position: left 1.6rem center;
}

.nav .navbar-item.is-active .accessibility {
  background: url('../public/images/icons/accessibility-white.svg');
  background-size: 2.4rem 2.4rem;
  background-repeat: no-repeat;
  background-position: left 1.6rem center;
}

.nav .brand {
  background: url('../public/images/icons/brand_icon.svg');
  background-size: 2.4rem 2.4rem;
  background-repeat: no-repeat;
  background-position: left 1.6rem center;
}

.nav .navbar-item.is-active .brand {
  background: url('../public/images/icons/brand_icon-white.svg');
  background-size: 2.4rem 2.4rem;
  background-repeat: no-repeat;
  background-position: left 1.6rem center;
}

.nav .tutorials {
  background: url('../public/images/icons/tutorials.svg');
  background-size: 2.4rem 2.4rem;
  background-repeat: no-repeat;
  background-position: left 1.6rem center;
}

.nav .navbar-item.is-active .tutorials {
  background: url('../public/images/icons/tutorials-white.svg');
  background-size: 2.4rem 2.4rem;
  background-repeat: no-repeat;
  background-position: left 1.6rem center;
}

.nav .resources {
  background: url('../public/images/icons/resources.svg');
  background-size: 2.4rem 2.4rem;
  background-repeat: no-repeat;
  background-position: left 1.6rem center;
}

.nav .navbar-item.is-active .resources {
  background: url('../public/images/icons/resources-white.svg');
  background-size: 2.4rem 2.4rem;
  background-repeat: no-repeat;
  background-position: left 1.6rem center;
}

/* */

.nav ul li ul .navbar-item {
  padding: 0.8rem 2.4rem;
  text-decoration: none;
  background: none;
  color: var(--am-base-colour-steel-900);
}

.nav ul li ul .navbar-item:hover {
  padding: 0.8rem 2.4rem;
  background-color: var(--am-base-colour-sapphire-100);
}

.nav ul li ul .navbar-item.active {
  padding: 0.8rem 2.4rem;
  font-weight: 700;
  background-color: var(--am-base-colour-sapphire-100);
}

/* ======================================= *\
   5.1. MENU BUTTON
\* ======================================= */

.menuBtn {
  display: none;
  position: absolute;
  top: 3.6rem;
  left: 2rem;
  z-index: 200;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  background: var(--am-base-colour-white);
  border-radius: 100%;
  padding: 0.4rem;
  width: 3.2rem;
  height: 3.2rem;
}

.menuBtn .bar {
  width: 2.4rem;
  height: 0.3rem;
  background-color: var(--am-base-colour-midnight-700);
  margin: 0.4rem 0;
  transition: all 0.3s ease-in-out;
  border-radius: 0.2rem;
}

.menuBtn:hover .bar {
  background-color: var(--am-base-colour-midnight-700);
}

.menuBtn:hover .bar:nth-child(1) {
  background-color: var(--am-base-colour-midnight-700);
}

.menuBtn:hover .bar:nth-child(2) {
  background-color: var(--am-base-colour-midnight-700);
}

.menuBtn:hover .bar:nth-child(3) {
  background-color: var(--am-base-colour-midnight-700);
}

.menuBtn.active .bar:nth-child(1) {
  transform: translateY(6px) rotate(45deg);
  background-color: var(--am-base-colour-sapphire-900);
}

.menuBtn.active .bar:nth-child(2) {
  opacity: 0;
  background-color: var(--am-base-colour-sapphire-900);
}

.menuBtn.active .bar:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg);
  background-color: var(--am-base-colour-sapphire-900);
}

/* ======================================= *\
   5.2. TABS
\* ======================================= */

.tabsContainer {
  display: block;
  padding-bottom: 1.6rem;
  margin-bottom: 2.4rem;
  overflow-x: auto;
}

.tabs {
  display: inline-block;
  box-shadow: 0px 2px 2px 0px #282F4026;
  border-radius: 100px;
  background: var(--am-base-colour-white);
  font-family: "Work Sans", sans-serif;
  font-size: 1.4rem;
  line-height: 125%;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.tabs ul {
  display: flex;
  flex-direction: row;
  gap: 1.6rem;
  padding: 0.4rem;
  margin: 0;
}

.tabs ul li {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0.8rem 1.6rem;
  border-radius: 100px;
  margin-bottom: 0;
  color: var(--am-base-colour-sapphire-900);

  transition: all .3s ease-in-out;
	-webkit-transition: all .3s ease-in-out;
	-ms-transition: all .3s ease-in-out;
}

.tabs ul li.active,
.tabs ul li.active:hover {
  color: var(--am-base-colour-white);
  background: var(--am-base-colour-sapphire-900);
  box-shadow: 0px 3px 6px 0px #0000001A;
}

.tabs ul li:hover {
  background: var(--am-base-colour-electric-lime-500);
  color: var(--am-base-colour-midnight-900);
}

/* ========================================================================== *\
   6. GENERAL CLASSES
\* ========================================================================== */

.hide {
  display: none;
}

.newBadge {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.2rem;
  color: var(--am-base-colour-midnight-700);
  padding: 0.8rem;
  width: 6.4rem;
  height: 6.4rem;
  background:  url('../public/images/flower-4.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  margin-bottom: 1.6rem;
}

.status {
  display: inline-flex;
  gap: 0.8rem;
  align-items: center;
  border-radius: 2.4rem;
  background-color: var(--am-base-colour-sapphire-200);
  color: var(--am-base-colour-midnight-700);
  padding: 0.4rem 1.6rem 0.4rem 0.8rem;
}

.status:before {
  content: "";
  display: block;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  background: var(--am-base-colour-sapphire-900);
}

.status.pending {
  background-color: var(--am-base-colour-citrine-200);
}

.status.pending:before {
  background: var(--am-base-colour-citrine-900);
}

.relative {
  position: relative;
}

.highlight {
  background-color: var(--am-base-colour-electric-lime-500);
  color: var(--am-base-colour-midnight-700);
}

.highlight.alt {
  background-color: var(--am-base-colour-hot-pink-500);
  color: var(--am-base-colour-white);
}

.highlight-midnight {
  background-color: var(--am-base-colour-midnight-700);
  color: var(--am-base-colour-white);
}

.highlight-white {
  border: 1px solid var(--am-base-colour-steel-200);
}

.resourceList {
  display: flex;
  flex-flow: row wrap;
  gap: 1.6rem;
}

.resourceList li {
  display: block;
  margin: 0;
}

.resourceList li .figma-btn {
  margin: 0;
}

.rule {
  height: 2.4rem;
  margin-bottom: 2.4rem;
  border-bottom: 3px solid var(--am-base-colour-steel-200);
}

.intro {
  display: block;
  max-width: 50rem;
}

.callout {
  display: block;
  background-color: var(--am-base-colour-sapphire-200);
  border-radius: 1.6rem;
  padding: 3.2rem;
}

.callout.light {
  background: var(--am-base-colour-sapphire-100);
}

.callout.white {
  background: var(--am-base-colour-white);
  border: 1px solid var(--am-base-colour-steel-200);
}

.callout.dark {
  background: var(--am-base-colour-sapphire-900);
  color: var(--am-base-colour-white);
}

.callout.dark.extra {
  background: var(--am-base-colour-midnight-700);
  color: var(--am-base-colour-white);
}

.callout.dark strong {
  color: var(--am-base-colour-white);
}

.callout.dark h2, 
.callout.dark h3 {
  color: var(--am-base-colour-white);
}

.callout.welcome {
  background: url(../public/images/welcome-banner.svg);
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: auto 90%;
  background-color: var(--am-base-colour-sapphire-200);
  padding: 4.8rem 4.8rem 3.2rem 4.8rem;
}

.callout.welcome h1,
.callout.welcome p {
  max-width: 360px;
}

.callout.padding-sm {
  padding: 1.6rem 1.2rem;
}

.tag-do,
.tag-dont,
.tag-notice {
  display: inline-block;
  border-radius: 100px;
  padding: 0.4rem 1.6rem 0.4rem 3.2rem;
  color: var(--am-base-colour-white);
  font-weight: 700;
  font-size: 1.4rem;
  font-family: "Work Sans", sans-serif;
  margin-bottom: 1.6rem;
}

.tag-do {
  background: url(../public/images/icons/check_small-white.svg);
  background-position: left 0.8rem center;
  background-repeat: no-repeat;
  background-size: 2.4rem 2.4rem;
  background-color: var(--am-base-colour-emerald-900);
}

.tag-dont {
  background: url(../public/images/icons/close_small-white.svg);
  background-position: left 0.8rem center;
  background-repeat: no-repeat;
  background-size: 2.4rem 2.4rem;
  background-color: var(--am-base-colour-ruby-900);
}

.border {
  display: block;
  border: 1px solid var(--am-base-colour-steel-200);
}

.padding-level-1,
.padding-level-2,
.padding-level-3 {
  display: block;
}

.padding-level-1 {
  padding-bottom: 1.6rem;
}

.padding-level-2 {
  padding-bottom: 2.4rem;
}

.padding-level-3 {
  padding-bottom: 3.2rem;
}

.spacer {
  display: block;
}

.spacer.level-1 {
  height: 1.6rem;
}

.spacer.level-2 {
  height: 2.4rem;
}

.spacer.level-3 {
  height: 3.2rem;
}

.spacer.level-4 {
  height: 4.8rem;
}

.thumb {
  display: block;
  border-radius: 1.6rem;
  width: 100%;
  height: 200px;
  margin-bottom: 1.6rem;
}

.thumb.thumb-1 {
  background: 
    url('../public/images/foundation-banner.svg'),
    var(--am-brand-colour-purple-purple-gradient);
  background-position: center center;
  background-repeat: no-repeat;
}

.thumb.thumb-2 {
  background: 
  url('../public/images/patterns-banner.svg'),
  var(--am-brand-colour-tan-pink-gradient);
  background-position: center center;
  background-repeat: no-repeat;
}

.thumb.thumb-3 {
  background:
    url('../public/images/tokens-banner.svg'),
    var(--am-brand-colour-yellow-tan-gradient);
  background-position: center center;
  background-repeat: no-repeat;
}

.thumb.thumb-4 {
  background:
    url('../public/images/accessibility-banner.svg'),
    var(--am-brand-colour-yellow-pink-gradient);
  background-position: center center;
  background-repeat: no-repeat;
}

.thumb.thumb-5 {
  background:
    url('../public/images/brand-banner.svg'),
    var(--am-brand-colour-blue-blue-gradient);
  background-position: center center;
  background-repeat: no-repeat;
}

.thumb.thumb-6 {
  background:
    url('../public/images/resources-banner.svg'),
    var(--am-brand-colour-blue-green-gradient);
  background-position: center center;
  background-repeat: no-repeat;
}

.figma-btn,
.figma-btn[rel=noreferrer],
.figma-btn[rel=external] {
  display: inline-block;
  font-size: 1.4rem;
  line-height: 125%;
  margin: 0 0 1.6rem 0;
  padding: 0.8rem 2.4rem 0.8rem 4.8rem;
  font-weight: 700;
  color: #1E1E1E;
  border: 1px solid #1E1E1E;
  border-radius: 100px;
  text-decoration: none;
  background: url('../public/images/figma-logo.svg');
  background-position: left 2.4rem center;
  background-repeat: no-repeat;
  background-size: auto 2.4rem;
  transition: all .3s ease-in-out;
	-webkit-transition: all .3s ease-in-out;
	-ms-transition: all .3s ease-in-out;
}

.figma-btn:hover {
  color: var(--am-base-colour-white);
  background-color: #1E1E1E;
}

.red {
  color: var(--am-base-colour-ruby-900);
}

.btn {
  display: inline-block;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 1px;
  background-color: var(--am-base-colour-sapphire-900);
  color: var(--am-base-colour-white);
  border-radius: 100px;
  padding: 1.2rem 4.8rem;
  font-size: 1.2rem;
  margin-bottom: 2.4rem;
  border: 2px solid var(--am-base-colour-sapphire-900);
  font-weight: bold;
  font-family: "Work Sans", sans-serif;
}

a.btn[rel=external],
a.btn[rel=noreferrer] {
  padding-right: 4.8rem;
  min-height: 0;
  background-image: none;
  background-color: var(--am-base-colour-sapphire-900);
}

a.btn[rel=external]:hover,
a.btn[rel=noreferrer]:hover {
  background-color: var(--am-base-colour-electric-lime-500);
}

.btn.small {
  padding: 0.8rem 2.4rem;
  font-size: 1rem;
}

.btn:hover {
  color: var(--am-base-colour-midnight-900);
  background: var(--am-base-colour-electric-lime-500);
  border-color: var(--am-base-colour-electric-lime-500);
  text-decoration: none;
}

.btn.secondary {
  background: transparent;
  color: var(--am-base-colour-sapphire-900);
}

.btn.secondary:hover {
  background: var(--am-base-colour-electric-lime-500);
  color: var(--am-base-colour-midnight-900);
}

.btn.tertiary {
  padding: 0;
  color: var(--am-base-colour-sapphire-900);
  background: transparent;
  border: none;
  display: flex;
  flex-direction: row;
  gap: 0.4rem;
}

.btn.tertiary:after {
  content: "";
  display: block;
  width: 2.4rem;
  height: 2.4rem;
  background: url('../public/images/ico-right-arrow.svg');
  background-size: 2.4rem 2.4rem;
  background-repeat: no-repeat;
}

.btn.tertiary:hover {
  color: var(--am-base-colour-midnight-700);
  border: none;
  background: transparent;
}

.btn.tertiary:hover:after {
  transform: translateX(0.4rem);
}

.btn.block {
  display: block;
  width: 100%;
  text-align: center;
}

/* ========================================================================== *\
   7. COLUMNS
\* ========================================================================== */

.columns {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.columns.wrap {
  flex-flow: row wrap;
}

.columns.flex-start {
  justify-content: flex-start;
  gap: 4%;
}

.columns .col {
	display: block;
}

.columns .col.right {
	text-align: right;
}

.center {
	text-align: center;
}

.centerV {
	align-items: center;
}

.endV {
	align-items: flex-end;
}

.flexStart {
	justify-content: flex-start !important;
}

.one_half {
	width:48%;
}

.one_third {
	width:30.66%;
}

.two_third {
	width:65.33%;
}

.one_fourth {
	width:22%;
}

.three_fourth{
	width:74%;
}

.one_fifth {
	width:16.8%;
}

.two_fifth{
	width:37.6%;
}

.three_fifth {
	width:58.4%;
}

.four_fifth {
	width:79.2%;
}

.one_sixth {
	width:13.33%;
}

.five_sixth {
	width:82.67%;
}

.numberGroup {
  display: flex;
  flex-direction: row;
  gap: 2.4rem;
}

.number {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 4.8rem;
  height: 4.8rem;
  border-radius: 100%;
  padding: 0.8rem;
  border: 2px solid var(--am-base-colour-sapphire-900);
  font-weight: bold;
  margin-top: -0.4rem;
}

.numberGroup.small {
  gap: 1.6rem;
}

.numberGroup.small .number {
  width:3.2rem;
  height: 3.2rem;
  margin-top: 0;
}
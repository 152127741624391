/* ========================================================================== *\
   BREAKPOINTS
\* ========================================================================== */

@import "ResponsiveMobile.css" screen and (max-width: 760px);
@import "ResponsiveTabletPortrait.css" screen and (min-width:761px) and (max-width:1000px);
@import "ResponsiveTabletLandscape.css" screen and (min-width:1001px) and (max-width:1236px);

/* ========================================================================== *\
   IMAGE RESIZE
\* ========================================================================== */

img{max-width: 100%; height: auto !important;}
img{ -ms-interpolation-mode: bicubic; }
.menuBtn {
  display: block;
}

.header {
  padding-left: 6.4rem;
}

.nav.landing-page {
  display: block;
}

.nav {
  position: absolute;
  left: -28rem;
  top: 0;
  width: 28rem;
  height: 100%;
  border-right: none;
  background: var(--am-base-colour-white);
  z-index: 100;
  transition: all 0.3s ease-in-out;
}

.nav.active {
  left: 0;
}

.nav ul {
  background: var(--am-base-colour-white);
  height: 100%;
  padding: 8rem 0 0 0;
}

.nav ul ul {
  padding: 0 0 0 0;
}

.nav.active:after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background: rgba(8, 14, 26, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index:-1;
  transition: all .3s ease-in-out;
  -webkit-transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out;
}

.content {
  width: 100%;
  border: none;
}

.content .inner {
  display: block;
  padding: 4.8rem 4.8rem 8rem 4.8rem;
  margin: 0 auto;
}

.content .inner .main {
  max-width: 100%;
}

.columns.reverse {
  flex-flow: wrap-reverse;
}

.content .inner .subNav {
  display: block;
  position: static;
  margin-bottom: 2.4rem;
  width: 100%;
  z-index: 1;
}

.content .inner .subNav ul {
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
  padding: 0.8rem;

}
.content .inner .subNav ul li:first-child {
  font-size: 1.4rem;
  line-height: 150%;
  padding: 0.4rem 0.8rem;
  margin: 0;
}

.content .inner .subNav ul li a:focus {
  color: var(--am-base-colour-steel-900);
  background: var(--am-base-colour-white);
}

/* === TYPOGRAPHY === */

.title-level-1-large {
  font-size: 4.8rem;
  line-height: 125%;
}

.title-level-1 {
  font-size: 4.2rem;
  line-height: 125%;
}

.title-level-1-small {
  font-size: 3.6rem;
  line-height: 125%;
}

.title-level-2-x-large {
  font-size: 4.2rem;
  line-height: 125%;
}

.title-level-2-large {
  font-size: 3.6rem;
  line-height: 125%;
}
.title-level-2 {
  font-size: 3rem;
  line-height: 125%;
}

.title-level-2-small {
  font-size: 2.4rem;
  line-height: 125%;
}

.title-level-2-x-small {
  font-size: 2rem;
  line-height: 125%;
}

.title-level-3-large {
  font-size: 2rem;
  line-height: 125%;
}

.title-level-3 {
  font-size: 1.8rem;
  line-height: 150%;
}

.title-level-3-small {
  font-size: 1.6rem;
  line-height: 125%;
}

.title-level-4 {
  font-size: 1.6rem;
  line-height: 125%;
}

.title-level-5 {
  font-size: 1.4rem;
  line-height: 125%;
}

.title-level-6 {
  font-size: 1.2rem;
  line-height: 125%;
}

.choose-btn.web .graphic {
  position: absolute;

  right: 0;
  top: -4.8rem;
  transform: scale(0.8,0.8);
}

.choose-btn.app .graphic {
  position: absolute;

  right: 3.2rem;
  top: -4.8rem;
  transform: scale(0.8,0.8);
}
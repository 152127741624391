.menuBtn {
  display: block;
  top: 2.8rem;
  left: 1.6rem;
}

.header {
  padding-left: 6.4rem;
  height: 8rem;
}

.large {
  font-size: 1.6rem;
}

.spacer.level-3,
.spacer.level-4 {
  height: 2.4rem;
}

.header .version {
  display: none;
}

.callout.welcome {
  background-image: none;
  padding: 2.4rem 8rem 2.4rem 2.4rem;
}

.callout.welcome .title-level-1 {
  font-size: 3rem;
}

.change-log-container .change-log-container-header .col:last-child {
  display: none;
}


.change-log-container .change-log-container-row {
  flex-flow: row wrap;
}

.change-log-container .col {
  display: block;
  max-width: 150px;
  width: 100%;
}

.change-log-container .col:last-child:before {
  content: "Details";
  color: var(--am-base-colour-steel-900);
  display: block;
  padding-bottom: 1.6rem;
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 125%;
}

.nav.landing-page {
  display: block;
}

.nav {
  position: absolute;
  left: -28rem;
  top: 0;
  width: 28rem;
  height: 100%;
  border-right: none;
  background: var(--am-base-colour-white);
  z-index: 100;
  transition: all 0.3s ease-in-out;
  overflow-y: auto;
}

.nav.active {
  left: 0;
}

.nav ul {
  background: var(--am-base-colour-white);
  min-height: 100%;
  padding: 8rem 0 0 0;
}

.nav ul ul {
  padding: 0 0 0 0;
}

.nav.active:after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background: rgba(8, 14, 26, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index:-1;
  transition: all .3s ease-in-out;
  -webkit-transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out;
}

.tabs {
  font-size: 1.2rem;
}

.tabs ul li {
  padding: 0.4rem 0.8rem;
}

/* tab overflow */

.table {
  overflow-x: auto;
}

/* .table.designCheckListTable table */

.table table thead {
  display: none;
}

.table table tbody,
.table table tbody tr,
.table table tbody tr td {
  display: block;
  border-bottom: none;
}

.table table tbody tr {
  padding: 0;
  border-bottom: 1px solid var(--am-base-colour-steel-200);
}

.table table tbody tr th {
  display: block;
  width: 100%;
}

.table table tbody tr td {
  padding: 0.8rem 1.6rem;
  color: var(--am-base-colour-steel-700);
  background: var(--am-base-colour-white);
  max-width: 100%;
}

.table table tbody tr td .badge,
.table table tbody tr th .badge {
  top: inherit;
  transform: translateY(0);
  bottom: 1.6rem;
  right: 1.6rem;
}

.table table tbody tr td:first-child {
  padding-top: 1.6rem;
}

.table table tbody tr td:last-child {
  padding-bottom: 1.6rem;
}

.table table tbody tr td:before {
  content: attr(rel);
  display: block;
  font-weight: bold;
  text-transform: capitalize;
  margin-bottom: 0;
  color: var(--am-base-colour-steel-900);
  font-size: 1.4rem;
}

.table table tbody tr td .figma-btn {
  margin-top: 1.6rem;
}

.nav.active {
  left: 0;
}

.content {
  width: 100%;
  border: none;
}

.content .inner {
  display: block;
  padding: 2.4rem 1.6rem 6.4rem 1.6rem;
  margin: 0 auto;
}

.content .inner .main {
  max-width: 100%;
}

.content .inner .subNav {
  display: none;
}

.columns {
  flex-flow: row wrap;
}

.one_half,
.one_third,
.two_third,
.one_fourth,
.three_fourth,
.one_fifth,
.two_fifth,
.three_fifth,
.four_fifth,
.one_sixth,
.five_sixth {
	width: 100%;
}

/* TYPOGRAPHY */

.title-level-1-large {
    font-size: 4rem;
    line-height: 125%;
  }
  
  .title-level-1 {
    font-size: 3.6rem;
    line-height: 125%;
  }
  
  .title-level-1-small {
    font-size: 3.0rem;
    line-height: 125%;
  }
  
  .title-level-2-x-large {
    font-size: 3.6rem;
    line-height: 125%;
  }
  
  .title-level-2-large {
    font-size: 3rem;
    line-height: 125%;
  }
  .title-level-2 {
    font-size: 2.4rem;
    line-height: 125%;
  }
  
  .title-level-2-small {
    font-size: 2rem;
    line-height: 125%;
  }
  
  .title-level-2-x-small {
    font-size: 1.8rem;
    line-height: 125%;
  }
  
  .title-level-3-large {
    font-size: 2rem;
    line-height: 125%;
  }
  
  .title-level-3 {
    font-size: 1.8rem;
    line-height: 150%;
  }
  
  .title-level-3-small {
    font-size: 1.6rem;
    line-height: 125%;
  }
  
  .title-level-4 {
    font-size: 1.6rem;
    line-height: 125%;
  }
  
  .title-level-5 {
    font-size: 1.4rem;
    line-height: 125%;
  }
  
  .title-level-6 {
    font-size: 1.2rem;
    line-height: 125%;
  }

  ol.inline {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

.brandColours,
.brandColours.third {
  grid-template-columns: repeat(1,1fr);
}

.brandColours .tile .colour,
.brandColours.third .tile .colour {
  height: auto;
  gap: 1.6rem;
}

.landingPageGrid {
  grid-template-columns: repeat(1, 1fr);
  gap: 2.4rem;
  margin-top: 1.6rem;
}

.choose-btn {
  padding: 1.6rem 2.4rem;
  height: auto;
  margin-bottom: 2.4rem;
  flex-direction: row;
  flex-flow: row-reverse;
  align-items: center;
  justify-content: space-between;
}

.choose-btn.web .graphic {
  position: relative;
  right: inherit;
  top: inherit;
  transform: scale(1,1);
}

.choose-btn.web .graphic {
  position: static;
  width: auto;
}

.choose-btn.web .graphic .device {
  width: 12rem;
}

.choose-btn.app .graphic {
  position: static;
  width: auto;
}

.choose-btn.app .graphic .device {
  width: 8rem;
}

.choose-btn p {
  display: none;
}

.choose-btn.app .graphic .flair-1,
.choose-btn.app .graphic .flair-2,
.choose-btn.web .graphic .flair-1,
.choose-btn.web .graphic .flair-2 {
  display: none;
}

.hideMobile{
  display: none;
}

.numberGroup {
  flex-flow: row wrap;
}

.numberGroup .col {
  width: 100%;
}

.number {
  width: 4rem;
  height: 4rem;
  font-size: 1.6rem;
}